
import { defineComponent, onMounted, ref, reactive } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ErrorMessage, Field, Form } from "vee-validate";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import { useToast } from "vue-toastification";
import ApiService from "@/core/services/ApiService";
import { useRouter, useRoute } from "vue-router";
import AddFiturPrice from "@/components/modals/forms/AddFiturPrice.vue";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import moment from "moment";
import { Modal } from "bootstrap";

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

interface items {
  disableButton: boolean;
  schoolId: string;
  tanggalSekarang: string;
  formMigrasi: any;
  preview: any;
}

export default defineComponent({
  name: "Progress PKS Sekolah",
  components: {
    AddFiturPrice,
    ErrorMessage,
    Field,
    Form,
    Loading, 
  },

  setup() {
    const submitButton = ref<HTMLElement | null>(null);
    const toast = useToast();
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const konfirmModalRef = ref<null | HTMLElement>(null);

    const isLoading = ref(false);
    const fullPage = ref(true);
    const isColor = "#007BFF";
    const isBackgroundColor = "#A3A3A3";
    const isOpacity = 0.5;

    let items = reactive<items>({
      disableButton: false,
      schoolId: "",
      tanggalSekarang: "",
      formMigrasi: {
        invoiceDate: "",
        detail: [
          {
            qty: "",
          },
        ],
      },
      preview: {
        total: "",
        diskon: "",
        harga: "",
        jumlahSiswa: "",
        pajak: "",
      },
    });

    const previewInvoice = () => {
      items.schoolId = JSON.parse(localStorage.getItem("user_account")!).schoolId;
      items.formMigrasi.detail[0].qty = JSON.parse(
        localStorage.getItem("qtyStudent")!
      );

      checkMigrasiSekolah();

    };

    const checkMigrasiSekolah = () => {
      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_student/student/school/check/" +
          items.schoolId
      )
        .then(({ data }) => {

          if (data == false) {
            toast.error("Sekolah belum terdaftar di PSP");
            isLoading.value = false;
          } else {
            getQtyInvoice();
            isLoading.value = false;
          }

        })
        .catch(({ response }) => {
          isLoading.value = false;
        });
      
    };

    const getQtyInvoice = () => {

      isLoading.value = true;

      ApiService.getWithoutSlug(
        "crmv2/main_invoice/invoice/invoice/check_detail_data?schoolId=" +
          items.schoolId +
          "&itemType=Migration&paymentStatus=UNPAID"
      )
        .then(({ data }) => {

          if (data == false) {

            if (items.formMigrasi.detail[0].qty === 0){
              toast.error("Data siswa dengan status 'baru ditambahkan' tidak tersedia, tidak dapat mendaftarkan ke PSP");

              isLoading.value = false;
              
            } else {

              isLoading.value = false;

              Swal.fire({
                title: "Daftar Siswa ke PSP",
                html: "Apakah anda yakin akan mendaftarkan siswa ke Platform Sekolah Pintar ? <br> Siswa yang terdaftar akan hilang dari menu siswa !",
                type: "warning",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                confirmButtonText: "Ok",
                cancelButtonText: "Kembali",
                buttonsStyling: false,
              }).then((result) => {
                if (result.value) {
                  previewInvoice2();
                }
              });
            }
          } else {
            isLoading.value = false;
            
            Swal.fire({
              title:
                "Ada tagihan daftar psp yang belum terbayar, silahkan bayar terlebih dahulu atau batalkan tagihan",
              text: ``,
              icon: 'warning',
              type: "warning",
              showCancelButton: true,
              showConfirmButton: false,
              cancelButtonClass: "btn btn-primary btn-fill",
              cancelButtonText: "Ok",
              buttonsStyling: false,
            }).then((result) => {

            });
            
          }
        })
        .catch(({ response }) => {});
    };

    const previewInvoice2 = () => {


      isLoading.value = true;

      items.disableButton = true;
      const toast = useToast();

      ApiService.postWithData(
        "crmv2/main_invoice/invoice/invoice/invoices/preview?schoolId=" +
          items.schoolId +
          "&title=Daftar%20PSP%20Tanggal%20" +
          items.tanggalSekarang +
          "&itemType=Migration",
        items.formMigrasi
      )
        .then((res) => {
          items.preview.total = res.data.detail[0].amount;
          items.preview.diskon = res.data.detail[0].discount;
          items.preview.harga = res.data.detail[0].price;
          items.preview.jumlahSiswa = res.data.detail[0].qty;
          items.preview.pajak = res.data.detail[0].tax;

          const modal = new Modal(document.getElementById("kt_modal_preview"));
          modal.show();

          items.disableButton = false;
          isLoading.value = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            router.push('/sign-in');
            isLoading.value = false;
          } else {
            toast.error(e.response.data.detail);
            items.disableButton = false;
            isLoading.value = false;
          }
        });
    };

    const submitAddInvoice = () => {

      isLoading.value = true;

      items.disableButton = true;
      const toast = useToast();

      ApiService.postWithData(
        "crmv2/main_invoice/invoice/invoice/invoices?schoolId=" +
          items.schoolId +
          "&title=Daftar%20PSP%20Tanggal%20" +
          items.tanggalSekarang +
          "&itemType=Migration",
        items.formMigrasi
      )
        .then((res) => {

          hideModal(konfirmModalRef.value);

          items.disableButton = false;
          
          Swal.fire({
            title:
              "Pendaftaran siswa ke PSP telah dilakukan. Cek Tagihan di menu invoice untuk menyelesaikan proses pembayaran",
            text: ``,
            type: "warning",
            showCancelButton: true,
            showConfirmButton: false,
            cancelButtonClass: "btn btn-primary btn-fill",
            cancelButtonText: "Ok",
            buttonsStyling: false,
          }).then((result) => {
            
          });

          isLoading.value = false;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toast.error(e.response.data.detail);
            router.push('/sign-in');
            isLoading.value = false;
          } else {
            toast.error(e.response.data.detail);
            items.disableButton = false;
            isLoading.value = false;
          }
        });

    };

    onMounted(() => {
      items.schoolId = JSON.parse(localStorage.getItem("user_account")!).schoolId;
      items.formMigrasi.detail[0].qty = JSON.parse(
        localStorage.getItem("qtyStudent")!
      );

      items.tanggalSekarang = moment().format("DD-MM-YYYY");
      items.formMigrasi.invoiceDate = moment().format("YYYY-MM-DD");
    });

    return {
      submitButton,
      items,
      previewInvoice,
      previewInvoice2,
      submitAddInvoice,
      getQtyInvoice,
      konfirmModalRef,

      isLoading,
      fullPage,
      isColor,
      isBackgroundColor,
      isOpacity,
    };
  },
});
