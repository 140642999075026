
import { defineComponent, onMounted, ref, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { MenuComponent } from "@/assets/ts/components";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useToast } from "vue-toastification";
import { hideModal } from "@/core/helpers/dom";
import DownloadSekolah from "@/components/1crm/siswa/download-Siswa.vue";

import DaftarPSP from "@/components/1crm/1sekolah/siswa/daftar-psp.vue";
import UploadModal from "@/components/1crm/1sekolah/siswa/upload-Modal.vue";

import moment from "moment";

import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

import Multiselect from "@vueform/multiselect";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    Field,
    DaftarPSP,
    UploadModal,
    Loading,
    Multiselect,
    DownloadSekolah,
  },
  props: {
    widgetClasses: String,
  },

  data() {
    return {
      isLoading: false,
      fullPage: true,
      isColor: "#007BFF",
      isBackgroundColor: "#A3A3A3",
      isOpacity: 0.5,

      migrasiModalRef: ref<null | HTMLElement>(null),
      disableButton: false,
      tableData: [],
      listSekolah: [],
      paging: {
        page: 0,
        totalPages: 0,
        size: 10,
        totalElements: 0,
        perPageOptions: [10, 25, 50, 100],
      },
      name: "",
      kelas: "",
      kelasDetail: "",
      identityNumber: "",
      tags: "",
      migrationStatus: "",
      schoolId: "",
      paymentId: "",
      tanggalSekarang: "",
      formMigrasi: {
        invoiceDate: "",
        detail: [
          {
            qty: "",
          },
        ],
      },

      comboMigrationStatus: [
        {
          name: "Baru Ditambahkan",
          _id: "ADDED",
        },
        {
          name: "Menunggu Pembayaran",
          _id: "WAITING PAYMENT",
        },
        {
          name: "Proses Daftar PSP",
          _id: "ON PROCESS",
        },
        {
          name: "Gagal Daftar PSP",
          _id: "FAILED",
        },
      ],

      role: "",

      preview: {
        total: "",
        diskon: "",
        harga: "",
        jumlahSiswa: "",
        pajak: "",
      },
    };
  },

  beforeMount() {
    this.getListSekolah();
    this.getData(this.paging.size, this.paging.page);

    this.schoolId = JSON.parse(localStorage.getItem("user_account")!).schoolId;

    this.tanggalSekarang = moment().format("DD-MM-YYYY");
    this.formMigrasi.invoiceDate = moment().format("YYYY-MM-DD");
    this.role = JSON.parse(localStorage.getItem("user_account")!).role;
  },

  computed: {
    to() {
      let highBound = this.from + this.paging.size;
      if (this.paging.totalElements < highBound) {
        highBound = this.paging.totalElements;
      }
      return highBound;
    },
    from() {
      return this.paging.size * this.paging.page;
    },
  },

  methods: {
    getData(size, page) {
      this.isLoading = true;

      const toast = useToast();

      this.schoolId = JSON.parse(
        localStorage.getItem("user_account")!
      ).schoolId;

      let search1 = "";
      if (this.schoolId) {
        search1 = "schoolId=" + this.schoolId + "&";
      } else {
        search1;
      }

      let search2 = "";
      if (this.name) {
        search2 = "name=" + this.name + "&";
      } else {
        search2;
      }

      let search3 = "";
      if (this.kelas) {
        search3 = "kelas=" + this.kelas + "&";
      } else {
        search3;
      }

      let search4 = "";
      if (this.kelasDetail) {
        search4 = "kelasDetail=" + this.kelasDetail + "&";
      } else {
        search4;
      }

      let search5 = "";
      if (this.identityNumber) {
        search5 = "identityNumber=" + this.identityNumber + "&";
      } else {
        search5;
      }

      let search6 = "";
      if (this.tags) {
        search6 = "tags=" + this.tags + "&";
      } else {
        search6;
      }

      let search7 = "";
      if (this.migrationStatus) {
        search7 = "migrationStatus=" + this.migrationStatus + "&";
      } else {
        search7;
      }

      ApiService.getWithoutSlug(
        "crmv2/main_student/student/office/all?" +
          search1 +
          search2 +
          search3 +
          search4 +
          search5 +
          search6 +
          search7 +
          "&isDone=false" +
          "&page=" +
          page +
          "&size=" +
          size +
          "&sort=createTime&dir=1"
      )
        .then(({ data }) => {
          this.tableData = data.content;
          this.paging.page = data.page;
          this.paging.size = data.size;
          this.paging.totalElements = data.totalElements;
          this.paging.totalPages = data.totalPages;

          this.isLoading = false;
          this.getQtyStudent();
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    getListSekolah() {
      this.isLoading = true;
      ApiService.getWithoutSlug("crmv2/main_school/school/school")
        .then(({ data }) => {
          this.listSekolah = data;
          localStorage.setItem("listSchool", JSON.stringify(data));
          this.isLoading = false;
        })
        .catch(({ response }) => {
          this.isLoading = false;
        });
    },

    pilihSekolah(event) {
      console.log("cek event = " + event);
      if (event) {
        this.schoolId = event;
        this.getSchoolName();
      } else {
        this.schoolId = "";
      }
      this.getQtyStudent();

      localStorage.setItem("sekolahId", JSON.stringify(this.schoolId));
      this.getData(this.paging.size, this.paging.page);
    },

    pilihMigrationStatus(event) {
      if (event) {
        this.migrationStatus = event;
      } else {
        this.migrationStatus = "";
      }
      this.getData(this.paging.size, this.paging.page);
    },

    getQtyStudent() {
      const toast = useToast();

      if (this.schoolId != null) {
        ApiService.getWithoutSlug(
          "crmv2/main_student/student/office/all?schoolId=" +
            this.schoolId +
            "&migrationStatus=ADDED&isDone=false&page=0&size=10&sort=name&dir=1"
        )
          .then(({ data }) => {
            this.formMigrasi.detail[0].qty = data.totalElements;

            localStorage.setItem(
              "qtyStudent",
              JSON.stringify(data.totalElements)
            );
          })
          .catch(({ e }) => {
            if (e.response.status == 401) {
              toast.error(e.response.data.detail);
              this.$router.push("/sign-in");
            } else {
              toast.error(e.response.data.detail);
            }
          });
      }
    },

    getSchoolName() {
      if (this.schoolId != null || this.schoolId != "") {
        ApiService.getWithoutSlug(
          "crmv2/main_school/school/school/" + this.schoolId
        )
          .then((res) => {
            localStorage.setItem("schoolName", JSON.stringify(res.data.name));
          })
          .catch((e) => {});
      }
    },

    handleNoDelete(index, item) {
      Swal.fire({
        title:
          "Tidak dapat menghapus siswa yang sudah dalam proses pendaftaran ke PSP",
        text: ``,
        type: "warning",
        showCancelButton: true,
        showConfirmButton: false,
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Kembali",
        buttonsStyling: false,
      }).then((result) => {});
    },

    handleDelete(index, item) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: `Data yang telah dihapus tidak dapat dikembalikan !`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        confirmButtonText: "Ya, hapus !",
        cancelButtonText: "Batal",
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.deleteRow(item);
        }
      });
    },

    deleteRow(item) {
      this.isLoading = true;
      const toast = useToast();

      ApiService.delete("crmv2/main_student/student/" + item._id)
        .then((res) => {
          toast.success("Hapus Berhasil");

          this.getData(this.paging.size, this.paging.page);
          this.isLoading = false;
        })

        .catch((e) => {
          toast.error(e.response.data.detail);
          this.isLoading = false;
        });
    },

    kosongkanSekolah() {
      this.schoolId = "";
    },

    submitAddInvoice() {
      this.disableButton = true;
      const toast = useToast();
      // setTimeout(() => {
      ApiService.postWithData(
        "crmv2/main_invoice/invoice/office/invoice?schoolId=" +
          this.schoolId +
          "&title=Daftar%20PSP%20Tanggal%20" +
          this.tanggalSekarang +
          "&itemType=Migration",
        this.formMigrasi
      )
        .then((res) => {
          this.disableButton = false;
        })
        .catch((e) => {
          hideModal(this.migrasiModalRef);
          this.disableButton = false;
          toast.error(e.response.data.detail);
        });
    },

    range(min, max) {
      const arr: any[] = [];
      for (let i = min; i <= max; i++) {
        arr.push(i);
      }
      return arr;
    },

    changePage(item) {
      const page = item - 1;
      this.getData(this.paging.size, page);
    },
    nextPage() {
      const page = this.paging.page + 1;
      this.getData(this.paging.size, page);
    },
    prevPage() {
      const page = this.paging.page - 1;
      this.getData(this.paging.size, page);
    },
    changePageSize(e) {
      const page = this.paging.page;
      this.getData(this.paging.size, page);
    },
  },
});
